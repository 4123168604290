// src/components/HeroSection.jsx

import React, {useContext} from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import video_en from '../assets/home/HERO_ENG.mp4'
import video_hu from '../assets/home/HERO_HUN.mp4'
import fallback from '../assets/coroporate/corporate.jpg'
import ConfigContext from "../context/ConfigContext";

const VideoBackground = styled('video')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: -1,
});

const FallbackImage = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: -1,
});

const HeroSection = ({title, subtitle}) => {
    const {language} = useContext(ConfigContext)
    return (
        <Box sx={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
            <VideoBackground autoPlay muted loop playsInline>
                <source src={language === 'en' ? video_en : video_hu} type="video/mp4" />
                <FallbackImage src={fallback} alt="Fallback Image" />
            </VideoBackground>
            {/*<Container sx={{ position: 'relative', zIndex: 1, color: 'white', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>*/}
            {/*    <Typography className='slight-shadow' variant="h2" component="h1" gutterBottom>*/}
            {/*        {title}*/}
            {/*    </Typography>*/}
            {/*    <Typography className='slight-shadow' variant="h5" component="p">*/}
            {/*        {subtitle}*/}
            {/*    </Typography>*/}
            {/*</Container>*/}
        </Box>
    );
};

export default HeroSection;
