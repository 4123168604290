import React from 'react';
import { Card, CardActionArea, CardMedia, Typography, Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const Overlay = styled(Box)({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',
    opacity: 0,
    transition: '.5s ease',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const HoverText = styled(Typography)({
    color: 'white',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
});

const HoverBodyText = styled(Typography)({
    color: 'white',
    fontSize: '14px',
    textAlign: 'center',
});

const StyledCard = styled(Card)({
    position: 'relative',
    '&:hover': {
        '& .MuiCardMedia-root': {
            filter: 'blur(5px)',
        },
        '& .overlay': {
            opacity: 1,
        },
    },
});

const DateOverlay = styled(Box)({
    position: 'absolute',
    top: 8,
    right: 8,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '2px 8px',
    borderRadius: '4px',
    fontSize: '12px',
});

const ImageCard = ({ image, title, link, description, onClick, fromDate, endDate, htmlDescription = null }) => {
    const isExternal = link?.startsWith('http');
    const LinkComponent = link ? (isExternal ? 'a' : Link) : 'div';

    const cardContent = (
        <>
            <CardMedia
                component="img"
                height="200"
                image={image}
                alt={title}
            />
            <Overlay className="overlay">
                <Stack spacing={1}>
                    <HoverText>{title}</HoverText>
                    {description && <HoverBodyText>{description}</HoverBodyText>}
                    {htmlDescription && <div style={{color: 'white'}} dangerouslySetInnerHTML={{ __html: htmlDescription }}/>}
                </Stack>
            </Overlay>
            {(fromDate || endDate) && (
                <DateOverlay>
                    {fromDate && endDate ? `${fromDate} - ${endDate}` : fromDate || endDate}
                </DateOverlay>
            )}
        </>
    );

    return (
        <StyledCard>
            {link || onClick ? (
                <CardActionArea
                    component={LinkComponent}
                    to={link}
                    href={link}
                    onClick={onClick}
                >
                    {cardContent}
                </CardActionArea>
            ) : (
                <Box>
                    {cardContent}
                </Box>
            )}
        </StyledCard>
    );
};

export default ImageCard;
