import Footer from "../components/Footer";
import {Box, Button, Typography} from "@mui/material";
import HeroImage from "../components/HeroImage";
import useScrollToTop from "../hooks/useScrollToTop";
import ImageStack from "../components/ImageStack";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import mainImage from "../assets/sustainalbility/Fenntarthatóság.jpeg"
import firstImage from "../assets/sustainalbility/innovation.jpg";
import secondImage from "../assets/sustainalbility/Travelife.jpg";
import thirdImage from "../assets/sustainalbility/Öko.jpg";
import fourthImage from "../assets/sustainalbility/etics.jpg";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {useHistory} from "react-router-dom";

const Sustainability = () => {
    useScrollToTop()
    const {t} = useTranslation()
    const history = useHistory()
    document.title = `Jet Travel | ${t('sustainability.title')}`

    const cards = [
        {
            image: firstImage,
            title: t('sustainability.firstCard'),
            description: t('sustainability.firstCardDescription'),
        },
        {
            image: secondImage,
            title: t('sustainability.secondCard'),
            description: t('sustainability.secondCardDescription'),
        },
        {
            image: fourthImage,
            title: t('sustainability.thirdCard'),
            description: t('sustainability.thirdCardDescription'),
        },
        {
            image: thirdImage,
            title: t('sustainability.fourthCard'),
            description: t('sustainability.fourthCardDescription'),
        },
    ]

    return <DrawerAppbar fade>
        <HeroImage description={t('sustainability.description')} title={t('sustainability.title')} underlineColor='#ff0000' image={mainImage}/>
        <div style={{marginTop: '64px', marginBottom: '64px'}}>
            <Box sx={{margin: 3}}>
                <Button sx={{marginBottom: 2}} onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>
            {/*<Typography variant='h2' gutterBottom textAlign='center'>{t('sustainability.headline')}</Typography>*/}
            <ImageStack images={cards}/>

        </div>
        <Footer />
    </DrawerAppbar>
}

export default Sustainability