import { Stack, Typography, Box, Container, Button, Grid, IconButton } from "@mui/material";
import Footer from "../components/Footer";
import ErrorHandlingForm from "../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../components/formValidation/CustomTextField";
import { useRef, useState } from "react";
import { validateRequired } from "../components/formValidation/validators";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import Loading from "../components/Loading";
import useCallDataApi from "../hooks/data";
import {useSnackbar} from "notistack";
import useScrollToTop from "../hooks/useScrollToTop";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {useHistory} from "react-router-dom";

const Contact = () => {
    const {t} = useTranslation()
    document.title = `Jet Travel | ${t('contact.contact')}`
    useScrollToTop()
    const history = useHistory()
    const {postData: postContact} = useCallDataApi('contact')
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false)
    const initialState = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        comment: ''
    }
    const [formData, setFormData] = useState(initialState);

    const [errors, setErrors] = useState({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        comment: false,
    });

    const validators = {
        first_name: [validateRequired],
        last_name: [validateRequired],
        phone: [],
        email: [validateRequired],
        comment: [validateRequired],
    };

    const contactRef = useRef(null);

    return (
        <DrawerAppbar>
            <Box sx={{ marginTop: '128px', marginBottom: '64px' }}>
                <Box sx={{margin: 3}}>
                    <Button sx={{marginBottom: 2}} onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
                </Box>

                <Container>
                    <Typography variant="h2" component="h1" gutterBottom textAlign='center'>
                        {t('contact.contact')}
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} sx={{ mx: 'auto' }}>
                            <ErrorHandlingForm
                                data={formData}
                                validators={validators}
                                setErrors={setErrors}
                                errors={errors}
                                setData={setFormData}
                                reference={contactRef}
                            >
                                <Stack spacing={2}>
                                    <Box>
                                        <Typography variant="body1" gutterBottom>
                                            {t('contact.lastName')}
                                        </Typography>
                                        <CustomTextField
                                            name='last_name'
                                            data={formData}
                                            setData={setFormData}
                                            errors={errors}
                                            setErrors={setErrors}
                                            validators={validators}
                                            sx={{width: '100%'}}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" gutterBottom>
                                            {t('contact.firstName')}
                                        </Typography>
                                        <CustomTextField
                                            name='first_name'
                                            data={formData}
                                            setData={setFormData}
                                            errors={errors}
                                            setErrors={setErrors}
                                            validators={validators}
                                            sx={{width: '100%'}}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" gutterBottom>
                                            {t('contact.phone')}
                                        </Typography>
                                        <CustomTextField
                                            name='phone'
                                            data={formData}
                                            setData={setFormData}
                                            errors={errors}
                                            setErrors={setErrors}
                                            validators={validators}
                                            sx={{width: '100%'}}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" gutterBottom>
                                            {t('contact.email')}
                                        </Typography>
                                        <CustomTextField
                                            name='email'
                                            data={formData}
                                            setData={setFormData}
                                            errors={errors}
                                            setErrors={setErrors}
                                            validators={validators}
                                            sx={{width: '100%'}}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" gutterBottom>
                                            {t('contact.comment')}
                                        </Typography>
                                        <CustomTextField
                                            name='comment'
                                            data={formData}
                                            setData={setFormData}
                                            errors={errors}
                                            setErrors={setErrors}
                                            validators={validators}
                                            sx={{width: '100%'}}
                                            multiline
                                            rows={3}
                                        />
                                    </Box>
                                    <Button variant='contained' onClick={() => {
                                        const data = contactRef.current.getData();
                                        if (data) {
                                            setLoading(true)
                                            postContact('', data)
                                                .then(() => {
                                                    enqueueSnackbar(t('general.success'), {variant: 'success'})
                                                    setFormData(initialState)
                                                })
                                                .finally(() => setLoading(false))
                                        }

                                    }}>{t('general.send')}</Button>
                                </Stack>
                            </ErrorHandlingForm>

                        </Grid>
                    </Grid>
                    <Box mt={4}>
                        <Typography variant="body1" gutterBottom>
                            {t('contact.contactText')}
                        </Typography>
                    </Box>
                    <Box mt={4} display="flex" justifyContent="space-around">
                        <Box textAlign="center">
                            <IconButton color="primary">
                                <LocationOnIcon fontSize="large" />
                            </IconButton>
                            <Typography variant="body1" gutterBottom>
                                H-1113 Budapest, Bocskai út 77-79
                            </Typography>
                        </Box>
                        <Box textAlign="center">
                            <IconButton color="primary">
                                <EmailIcon fontSize="large" />
                            </IconButton>
                            <Typography variant="body1" gutterBottom>
                                jettravel@jettravel.hu
                            </Typography>
                        </Box>
                        <Box textAlign="center">
                            <IconButton color="primary">
                                <PhoneIcon fontSize="large" />
                            </IconButton>
                            <Typography variant="body1" gutterBottom>
                                +36 1 209 2110
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Footer />

            <Loading isLoading={loading}/>
        </DrawerAppbar>
    );
}

export default Contact;
