import HeroImage from "../../components/HeroImage";
import {Box, Button, Container, Grid, Stack, Typography} from "@mui/material";
import Footer from "../../components/Footer";
import useScrollToTop from "../../hooks/useScrollToTop";
import useCallDataApi from "../../hooks/data";
import {useContext, useEffect, useState, useRef} from "react";
import Loading from "../../components/Loading";
import {useHistory, useParams} from "react-router-dom";
import ImageCard from "../../components/ImageCard";
import {repairImageUrl} from "../../utils/utils";
import ConfigContext from "../../context/ConfigContext";
import CustomDialog from "../../components/CustomDialog";
import ErrorHandlingForm from "../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../components/formValidation/CustomTextField";
import ImageGallery from "../../components/ImageGallery";
import {
    validateEmail,
    validateRequired
} from "../../components/formValidation/validators";
import {useSnackbar} from "notistack";
import DrawerAppbar from "../../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import logo from '../../assets/shared/jet-adventure-logo-white-01.png'
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";


const SkiPlaces = () => {
    useScrollToTop()
    const {getData: fetchSkiPlace} = useCallDataApi('ski-place')
    const {postData: fetchAccommodations} = useCallDataApi('ski-accommodation')
    const {postData: postInquiry} = useCallDataApi('ski-accommodation-inquiry')
    const [skiAccommodations, setSkiAccommodations] = useState([])
    const [skiPlace, setSkiPlace] = useState({})
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState(1)
    const [accommodationDialogOpen, setAccommodationDialogOpen] = useState(false)
    const params = useParams()
    const {enqueueSnackbar} = useSnackbar()
    const {baseUrl, language} = useContext(ConfigContext)
    const {t} = useTranslation()
    const history = useHistory()

    const initialState = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        comment: '',
    }
    const [formData, setFormData] = useState(initialState);

    const [errors, setErrors] = useState({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        comment: false,
    });

    const validators = {
        first_name: [validateRequired],
        last_name: [validateRequired],
        phone: [validateRequired],
        email: [validateRequired, validateEmail],
        comment: []
    };

    const contactRef = useRef(null);

    const findAccommodation = id => skiAccommodations.find(a => a.id === id)

    useEffect(() => {
        setLoading(true)
        fetchSkiPlace(params?.region).then(r => {
            setSkiPlace(r)
            document.title = `Jet Travel | ${r?.translations?.[language]?.name}`
            fetchAccommodations('for_place/', {place_id: params?.id}).then(a => {
                setSkiAccommodations(a)
            })
        }).finally(() => setLoading(false))
    }, [])


    return <DrawerAppbar fade>
        <HeroImage logo={logo} title={skiPlace?.translations?.[language]?.name} underlineColor='#ff0000' image={skiPlace?.image}/>
        <div style={{marginTop: '64px', marginBottom: '64px'}}>
            <Box sx={{margin: 3}}>
                <Button sx={{marginBottom: 2}} onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>

            <Typography variant='h2' gutterBottom
                        textAlign='center'>{skiPlace?.translations?.[language]?.name}</Typography>

            <Grid container spacing={4} justifyContent="center" alignItems="center">

                {skiAccommodations?.map((accommodation, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <ImageCard image={repairImageUrl(accommodation.image, baseUrl)}
                                   htmlDescription={accommodation?.translations?.[language]?.description}
                                   title={accommodation?.translations?.[language]?.name}
                                   onClick={() => {
                                       setCurrent(accommodation?.id)
                                       setAccommodationDialogOpen(true)
                                   }}/>
                    </Grid>
                ))}
            </Grid>


        </div>
        <Footer/>

        <CustomDialog
            open={accommodationDialogOpen}
            title={findAccommodation(current)?.translations?.[language]?.name}
            handleClose={() => setAccommodationDialogOpen(false)}
            actions={<Stack spacing={2} direction='row'>
                <Button variant='outlined' onClick={() => {
                    setAccommodationDialogOpen(false)
                }}>{t('general.cancel')}</Button>
                <Button variant='contained' onClick={() => {
                    const data = contactRef.current.getData()
                    if (data) {
                        setLoading(true)
                        postInquiry('', {...data, accommodation: current})
                            .then(() => enqueueSnackbar(t('general.success'), {variant: 'success'}))
                            .finally(() => setLoading(false))
                    }
                }}>{t('general.send')}</Button>
            </Stack>}
        >
            <Container>
                <Typography marginTop={4} variant="h2" component="h1" gutterBottom textAlign='center'>
                    {t('ski.accommodationDetail')}:
                </Typography>
                {/*<Typography marginTop={1} variant="body1" gutterBottom textAlign='left'>*/}
                {/*    {findAccommodation(current)?.description}*/}
                {/*</Typography>*/}
                <div dangerouslySetInnerHTML={{ __html: findAccommodation(current)?.translations?.[language]?.description }}/>
            </Container>
            <ImageGallery images={findAccommodation(current)?.images?.map(i => ({
                alt: i?.id,
                src: repairImageUrl(i?.image, baseUrl)
            }))}/>
            <Container>
                <Typography marginTop={4} variant="h2" component="h1" gutterBottom textAlign='center'>
                    {t('general.getQuote')}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} sx={{mx: 'auto'}}>
                        <ErrorHandlingForm data={formData}
                                           validators={validators}
                                           setErrors={setErrors}
                                           errors={errors}
                                           setData={setFormData}
                                           reference={contactRef}>
                            <Stack spacing={2}>
                                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{width: '100%'}}>
                                    <Box sx={{width: '100%'}}>
                                        <Typography variant="body1" gutterBottom>
                                            {t('general.lastName')}
                                        </Typography>
                                        <CustomTextField
                                            name='last_name'
                                            data={formData}
                                            setData={setFormData}
                                            errors={errors}
                                            setErrors={setErrors}
                                            validators={validators}
                                            sx={{width: '100%'}}
                                        />
                                    </Box>
                                    <Box sx={{width: '100%'}}>
                                        <Typography variant="body1" gutterBottom>
                                            {t('general.firstName')}
                                        </Typography>
                                        <CustomTextField
                                            name='first_name'
                                            data={formData}
                                            setData={setFormData}
                                            errors={errors}
                                            setErrors={setErrors}
                                            validators={validators}
                                            sx={{width: '100%'}}
                                        />
                                    </Box>
                                </Stack>
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        {t('general.phone')}
                                    </Typography>
                                    <CustomTextField
                                        name='phone'
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        {t('general.email')}
                                    </Typography>
                                    <CustomTextField
                                        name='email'
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant="body1" gutterBottom>
                                        {t('general.comment')}
                                    </Typography>
                                    <CustomTextField
                                        name='comment'
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                        multiline
                                        rows={3}
                                    />
                                </Box>
                            </Stack>

                        </ErrorHandlingForm>
                    </Grid>
                </Grid>

            </Container>

        </CustomDialog>

        <Loading isLoading={loading}/>
    </DrawerAppbar>
}

export default SkiPlaces