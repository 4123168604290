import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    home: {
                        title: "35 years of leadership in domestic business and professional tourism",
                        subtitle: "We have been a leading player in the domestic business and professional tourism sector for 35 years. Our aim is to provide you with a reliable backdrop and comprehensive tourism services for your business trips and professional events, but you can also count on us for your leisure trips. Our services are flexible, punctual, fast and customer-oriented.",
                        in_numbers: 'Jet Travel in numbers',
                        refernces: 'References',
                        fifthNumber: "",
                        sixthNumber: "%",
                        seventhNumber: "",
                        eighthNumber: "+",
                        ninethNumber: "+",
                        tenthNumber: "+",
                        fifthNumberDescription: "qualified colleagues",
                        sixthNumberDescription: "Hungarian ownership",
                        seventhNumberDescription: "flight tickets issued per year",
                        eightNumberDescription: "events per year",
                        ninethNumberDescription: "incentive trips per year",
                        tenthNumberDescription: "training camps per year",
                        corporateTitle: "Corporate travel",
                        corporateDescription: "The professional experience of the last 35 years in business travel has enabled us to work with nearly 350 active contracted clients, for whom we issue 30,000 airline tickets within 12 months. Our clients include representatives from the automotive, telecommunications, media, and public sectors. We are proud that more and more sports associations and clubs are choosing us to organize their official trips.",
                        incentiveHeadline: "MICE & Bleisure",
                        incentiveDescription: "If you want to step out of your comfort zone with your event, team-building, or incentive trip, and stray off the beaten path, you’ve come to the right place. Would you like a professional training camp for your club team? Our experienced and enthusiastic team will organize your corporate event, incentive trip, or training camp from A to Z. Trust us with the organization, and we guarantee success!",
                        travelWithUsTitle: "Travel with Us",
                        travelWithUsDescription: "Are you unsure where to spend your vacation? Do you have an idea but lack the time or desire to organize it? We take the burden of organization off your shoulders, whether it’s an adrenaline-boosting adventure or culinary tour, a vacation, a sea cruise, or skiing at popular or lesser-known locations. Would you rather join a group trip? Then you’re in the right place with us. Our colleagues will find your dream trip tailored to your needs. On our website, you’ll find the most reliable Hungarian travel organizers' offers all in one place. Leave the travel arrangements to us, and enjoy a perfect vacation without worries!",
                        seaTravelTitle: "Cruise",
                        seaTravelDescription: "A sea cruise is not just a trip, but a way of life. The countless experiences offered by a cruise are unforgettable. Every day brings different sights and cultures, pampering services, and the culinary diversity of the world awaits travelers on board. Join the community of sea travelers and explore the world with us on the waves! We offer a wide range of individual and group sea cruises to ensure everyone finds the perfect experience for their holiday or relaxation. Choose both adventure and luxury, and enjoy the freedom and wonders of the sea on your vacation—at an affordable price.",
                        sportTitle: "Sport trips",
                        sportDescription: "Jet Travel is a team of experts dedicated to sports and sports tourism. We have assisted with the travel and accommodation arrangements in 17 sports, as well as the complete organization of world championships and training camps. Experience another dimension of sports tourism with us, the thrill of cheering! During our exclusive sports trips, we present the American continent from a unique perspective. In addition to exciting sporting events, cultural sights also play an important role in our programs. Attending NHL, NBA, and NFL games with expert guidance completes the experience.",
                        skiHeadline: "Ski",
                        skiDescription: "Austria is a paradise for winter sports. Austrian ski resorts are world-renowned for their excellent, well-maintained slopes, infrastructure, and welcoming atmosphere. Austria meets the needs of both beginner and experienced skiers and snowboarders, but Austrian ski resorts are also excellent destinations for families. Austrian ski resorts offer more than just skiing. You can try snow biking, enjoy great local hiking opportunities, or relax in a wellness center. Can't find the accommodation or ski resort you had in mind in our offers? Contact us, we don't know the meaning of 'impossible!'",
                    },
                    contact: {
                        contact_text: 'If you have any questions or need further information about our travel services, ' +
                            'please write to us using the above field. Please note that our colleagues only read ' +
                            'messages on working days during business hours (08:30 – 17:00). In case of urgent matters, ' +
                            'please do not use this interface but call our emergency hotline. Outside of business hours, ' +
                            'we can only assist with urgent cases reported through the emergency hotline!',

                        contact: "Contact Us",
                        firstName: "First Name",
                        lastName: "Last Name",
                        phone: "Phone",
                        email: "Email",
                        comment: "Comment",
                        contactText: "If you have any questions or need further information, please feel free to contact us."
                    },
                    general: {
                        send: "Send",
                        success: "Your message has been successfully sent.",
                        getQuote: "Get a Quote",
                        firstName: "First Name",
                        lastName: "Last Name",
                        phone: "Phone",
                        email: "Email",
                        comment: "Comments",
                        back: "Back",
                        cancel: "Cancel",
                        sum: "Sum",
                        open: "Open",
                    },
                    errors: {
                        charError: 'Can only contain lowercase letters without accents (a-z)',
                        minLengthError: 'Must be at least {{minLength}} characters long',
                        maxLengthError: 'Can be at most {{maxLength}} characters long',
                        emailError: 'Invalid email address',
                        vatNumberError: 'Invalid VAT number, (xxxxxxxx-y-zz)',
                        numberError: 'Can only contain numbers',
                        nonNegativeError: 'Cannot be a negative number',
                        requiredError: 'Cannot be empty',
                        dateError: 'Invalid date',
                    },
                    aboutUs: {
                        title: 'About Us',
                        description: 'More than a travel agency',
                        description2: 'We are a major player in domestic business and professional tourism. \n' +
                            'As a result of our dedication, we have been awarded the prestigious national professional award "THE BEST CORPORATE TRAVEL AGENCY" 3 times and since January 2018 we have been the exclusive Hungarian partner of ATPI Corporate Travel.\n' +
                            'Our aim is to provide our clients with a reliable background and comprehensive tourism services for their business trips and professional events, with a flexible, punctual and fast customer-oriented service. Our work is defined by flexibility, reliability and quality at the highest level.\n',
                        headline: 'A leading role in domestic business and professional tourism for 35 years',
                        firstCard: 'Why Jet Travel?',
                        firstCardDescription: 'Our goal is to provide our clients with a reliable background and comprehensive tourism services for their business trips and professional events, with a flexible, punctual and fast customer-oriented service. Our work is defined by flexibility, reliability and quality at the highest level.',
                        secondCard: "Our Team",
                        secondCardDescription: "Our team is made up of professional, enthusiastic and creative professionals with a deep understanding of business and professional tourism. We provide our clients with a high quality, complete business travel service tailored to their individual needs and ideas. You can also count on us for your leisure travel, whether it's a themed sports trip, cruise, beach holiday or even skiing.",
                        thirdCard: "EU Courses",
                        thirdCardDescription: "Explore the various EU-funded courses and programs we offer.",
                        fourthCard: "Our References",
                        fourthCardDescription: "See what our clients say about our exceptional service and expertise."

                    },
                    whyJet: {
                        title: "Why Jet Travel",
                        description: "Discover the benefits of choosing Jet Travel for your corporate travel needs.",
                        headline: "Why Choose Jet Travel?",
                        firstCard: "Everything you need for your business trip in one place",
                        firstCardDescription: "We provide you with a complete services for your business trip, from buying tickets, booking accommodation to conference registration.",
                        secondCard: "Keeping up with progress",
                        secondCardDescription: "Our business travel services are powered by the latest technologies, databases and search engines to provide you with the best value for money.",
                        thirdCard: "Time is Money",
                        thirdCardDescription: "You know this very well. Thanks to our staff's many years of professional experience, they can easily navigate in the maze of booking interfaces and always find the best options. Don't waste your valuable time organising your business trip. At your request, we can also help you to develop your company's travel policy, based on the information we have at our disposal.",
                        fourthCard: "Dedicated administrator",
                        fourthCardDescription: "A personal relationship is an essential element of successful cooperation. We provide you with a dedicated administrator.",
                        fifthCard: "24/7 assistance - Fast response",
                        fifthCardDescription: "You can rely on us to solve your urgent business travel problems 24/7, even after working hours. Cancellation? Change of itinerary? Don't worry, we'll notify you as quickly as possible and deal with any problems as quickly as possible.",
                        sixthCard: "There is no impossible",
                        sixthCardDescription: "On your business trip, would you like to transport an instrument, a piece of sports equipment, special equipment or an animal? Need to organise multiple programmes, people and locations? We can solve these seemingly complicated situations. We'll save you from paying unnecessary, last-minute surcharges out of necessity."
                    },
                    corporate: {
                        title: "Corporate travel",
                        description: "Over the past 35 years of professional experience in business travel has enabled us to work with nearly 350 active contracted customers, for whom we issue 30,000 tickets in 12 months. Our customers include representatives from the automotive, telecommunications, media and public sectors. We are proud that more and more sports federations and clubs are choosing us to organise their official travel.",
                        heading: "Our Services",
                        heading2: "Our business policy",
                        inNumbers: "Corporate in Numbers",
                        description2: "Flexibility: tailor-made solutions for all needs.\n" +
                            "Reliability: security and predictability at every step.\n" +
                            "Quality at the highest level.",
                        firstCard: "Ticket - On land, on water, in the air",
                        firstCardDescription: "We will find you the fastest and safest route to your destination, tailored to your business trip. As an IATA agency, we can book you on any airline, but you can also book your bus, train or boat tickets with us.",
                        secondCard: "Booking accommodation - A good night's sleep is the keys to your success",
                        secondCardDescription: "Thanks to our international booking systems and extensive partnerships - we can book you the ideal accommodation for your business trip in any category at a competitive price.",
                        thirdCard: "Airport transfer? Car rental? - Anywhere in the world",
                        thirdCardDescription: "You can count on us for competitive prices and reliable partners (Hertz, Avis, Europcar, etc.) for your business trip. We provide a full credit voucher for your car rental, avoiding extra costs on the spot.",
                        fourthCard: "Visa administration - to countries all over the world",
                        fourthCardDescription: "Don't get lost in the maze of visa applications and entry permits. We can arrange your visa to any country in the world (except where individual processing is mandatory).",
                        fifthCard: "Insurance - Keep your trip safe! ",
                        fifthCardDescription: "We offer complex insurance services for all parts of the world (Alfa, EUB and Colonnade Insurers). One of the keys to a relaxed journey: Accident, sickness, luggage insurance (ebben nem vagyok biztos)",
                        sixthCard: "Custom Itineraries",
                        sixthCardDescription: "On request, we can provide our clients with itemised monthly or quarterly statements of turnover for the previous period. We use a customised information system to constantly analyse our clients' travel patterns, so that we can always provide them with up-to-date advice.",
                        firstNumber: "years",
                        firstNumberDescription: "of experience in business and professional tourism",
                        secondNumber: "customers",
                        secondNumberDescription: "served per day",
                        thirdNumber: "top",
                        thirdNumberDescription: "most influential corporate travel companies in Hungary",
                        fourthNumber: "times",
                        fourthNumberDescription: "winner of the \"Best Corporate\" travel agency award",
                    },
                    csr: {
                        title: "CSR",
                        description: "Corporate social responsibility (CSR) is an integral part of our corporate culture",
                        headline: "Our CSR Initiatives",
                        firstCardTitle: 'Ensuring workplace conditions',
                        firstCardDescription: 'We believe that our employees can identify with and authentically represent our company\'s values to our customers and society if they themselves believe that it is good to be part of Jet Travel. To do this, we need to create the right supportive environment. This means not only regular professional and language training, but also a family- and pet-friendly workplace and the possibility to work from home. We support and recognise positive initiatives and reward outstanding performance by our colleagues.',
                        secondCardTitle: 'Small deeds, big dreams',
                        secondCardDescription: 'Ssmall deeds can often turn big dreams into reality. That\'s why we have been supporting the "Magic Lantern Foundation" for many years, which has helped grant the wishes of many children with long-term illnesses. We also participate on an ad hoc basis in donations to kindergartens, schools and special social institutions.',
                        thirdCardTitle: 'Sport - their success is our success',
                        thirdCardDescription: 'We are committed to sport. We regularly support sports clubs so that more athletes can achieve the best results of their lives.',
                        fourthCardTitle: 'Turizmus és fenntarthatóság',
                        fourthCardDescription: 'Coming soon',
                    },
                    colleagues: {
                        title: "Our Team",
                        description: "Ismerje meg csapatunkat, akik az utazásához magas minőségű, teljes körű utazási szolgáltatásokat biztosítják."
                    },
                    incentive: {
                        title: "Incentive Travel",
                        description: "What is the secret of successful professional tourism? Creativity, networking and a good idea.\n" +
                            "If you're looking to take your event, team building or incentive trip out of your comfort zone and off the beaten track, you've come to the right place.\n" +
                            "We know the difference between business tourism, business travel and leisure services.\n" +
                            "Our experienced and enthusiastic team can organise your corporate event or incentive trip from A to Z. We'll come up with the concept, find the best venue, and spice it all up with special programmes. We can design the image and creative content on demand, provide online registration, manage the accommodation contingent, conclude the contracts, handle the financial processes and ensure the perfect execution for 10 to 1000 people.",
                        headline: "Tailored Corporate Travel Experiences",
                        inNumbers: "Our Achievements in Numbers",
                        numberOfGuests: "Number of Guests",
                        startDate: "Start Date",
                        endDate: "End Date",
                        inquiryType: "Inquiry Type",
                        catering: "Catering Options",
                        accommodation: "Accommodation",
                        "3starHotel": "3-Star Hotel",
                        "4starHotel": "4-Star Hotel",
                        "5starHotel": "5-Star Hotel",
                        "firstCard": "Meeting",
                        firstCardDescription: "The Success of a Business Meeting Lies in the Details\nDid you know that the secret to a company's success can even depend on the quality of its business meetings? Perfect venue selection, creative program ideas, and high-quality service! You can count on us for all of this! \n\nLeave the organization to the professionals, whether it's a small business meeting, a business breakfast, a cycle meeting, or a workshop.",

                        secondCard: "Incentive Trips",
                        secondCardDescription: "To Make the Experience Truly Motivating\nOrganizing a customized incentive trip is an excellent way to reward and motivate your employees through memorable adventures. Did you know that the success of an incentive trip largely depends on the carefully selected destination, creative programs, and perfect organization? Custom-made trips and special ideas? We always have an extra good idea! \n\nWhich type of incentive trip suits your motivational program and company: \n• Luxury incentive trips to exotic destinations \n• Adventurous incentive programs for adrenaline seekers \n• Cultural incentive trips to the world’s most interesting cities \n• Wellness and relaxation incentive trips for complete relaxation",

                        thirdCard: "Conference",
                        thirdCardDescription: "Don't Get Lost in the Maze of Conference Planning!\nConference planning is a complex and demanding process that requires a lot of preparation and attention. With proper planning and execution, we can create a memorable and successful event. Don’t get lost in the maze of conference planning and execution! You can rely on us for setting objectives, preparing budgets, selecting venues, organizing programs, inviting speakers, providing technical equipment and support, handling marketing and promotional tasks, and managing accommodation contingencies. We will ensure everything runs smoothly and successfully. Your success is also our success!",

                        fourthCard: "Exhibitions & Events",
                        fourthCardDescription: "Don’t waste your time! Leave the organization to us! \n\nDo you want to attend an exhibition or conference and need registration, tickets, and accommodation? Don’t waste your time; trust us with the organization and booking! We will take the burden off your shoulders so you can focus on the more important things.",

                        fifthCard: "Corporate Events and Team Building",
                        fifthCardDescription: "Forge Your Team Together!\nOrganizing a corporate event is not enough; it must perfectly reflect your company's image and brand. We always have a stunning idea. We are not afraid of challenges, whether it’s an exciting or entertaining team-building event in Budapest, the countryside, the city, or the wilderness. The success of team-building provides lasting experiences that your colleagues will associate with your company. We also organize family days for hundreds of people with engaging and cohesive programs for all ages. Trust us with event planning, and we’ll ensure every event is memorable and successful.",

                        sixthCard: "Bleisure",
                        sixthCardDescription: "More Than a Business Trip\nBusiness and leisure travel combined? Why not. If you want to increase your employees’ commitment to your company, the solution is bleisure. More and more workplaces are recognizing the positive effects of combining business and leisure travel. We can help you make sure that a business trip is not just work but also provides motivation and enjoyment for your colleagues.",
                        seventhCard: "Sports Tourism and Organisation of Sports Events",
                        seventhCardDescription: "Individual and Team level\nOur company is a team of experts dedicated to sport and sports tourism. Over the years, we have assisted in the outbound and inbound travel and accommodation bookings of individual athletes, teams and sports federations. We have extensive experience in the full range of world competitions and training camps. We are currently active in 17 sports and are proud to be a leading player in domestic sports tourism.",
                        firstNumber: "+",
                        firstNumberDescription: "Incentive travels yearly",
                    },
                    notFound: {
                        title: "Page Not Found",
                        headline: "Page Not Found",
                        text: "It seems you have navigated to a wrong place. Don't worry, even the best travelers get lost sometimes!"
                    },
                    sustainability: {
                        title: "Sustainability",
                        description: "Jet Travel is dedicated to fostering environmental responsibility across the tourism sector and within our own company. By promoting a culture of sustainability, we strive to encourage our employees, partners, and customers to make environmentally conscious choices that contribute to a healthier planet. We believe that small, consistent steps can lead to significant positive change.",
                        // headline: "Learn More About Our Sustainable Practices",
                        firstCard: 'Innovation for a sustainable future',
                        firstCardDescription: 'Our goal is to promote sustainable tourism and environmentally friendly travel. Our mission is to assist our customers in making more sustainable travel choices. To support this, we are in the process of developing our CO2 calculator, which will focus specifically on air travel and will allow our partners to accurately assess the environmental impact of their travel and provide the opportunity to offset carbon emissions.',
                        secondCard: 'Travelife Certification ',
                        secondCardDescription: 'Jet Travel is committed to achieving Travelife certification, a prestigious recognition for travel agencies that meets rigorous sustainability standards. This certification process requires a comprehensive assessment of our environmental, social and governance practices, ensuring that we operate to the highest standards of sustainability. Achieving this certification will demonstrate our commitment to continuous improvement and our leadership role in sustainable tourism.',
                        thirdCard: 'Compliance with ethical standards Ethical Standards',
                        thirdCardDescription: 'Corporate Social Responsibility is a top priority for Jet Travel. Both our domestic and international partners are expected to meet the highest ethical standards to avoid the risk of illegal and/or child labor. In addition to our commitment to being an inclusive workplace, we expect our employees to be treated with respect and appreciation. We not only comply with international labor standards, but also promote a culture of respect and dignity throughout our business practice.',
                        fourthCard: 'Reducing the carbon footprint',
                        fourthCardDescription: 'Jet Travel is actively working to reduce energy consumption in the office. Our efforts include going paperless in the office, promoting telecommuting and sustainable transportation options such as car sharing, bicycles, electric scooters and public transportation. These measures are in line with our commitment to sustainability and help us to reduce our carbon footprint and promote a cleaner environment',
                    },
                    travelWithUs: {
                        title: "Travel With Us",
                        description: "Explore the world with Jet Travel",
                        headline: "Discover Our Travel Options",
                        sea: "Cruises",
                        organized: "Organized trips",
                        text: "Join us on a journey of a lifetime with a variety of travel packages designed to suit every preference. Whether you're looking for luxury cruises, adventure trips, or cultural tours, we have something for everyone."
                    },
                    menu: {
                        home: "Home",
                        contact: "Contact",
                        sport: "Sport",
                        aboutUs: "About Us",
                        corporate: "Corporate",
                        incentive: "Incentive",
                        travelWithUs: "Travel With Us",
                        sustainability: "Sustainability",
                        csr: "CSR",
                        blog: "Blog",
                        ski: "Ski Regions",
                        colleagues: "Colleagues",
                        cruises: "Cruise"
                    },
                    footer: {
                        aboutUs: "About Us",
                        aboutUsDescription: "More than a travel agency.",
                        links: "Quick Links",
                        contact: "Contact",
                        allRightsReserved: "All rights reserved.",
                        impressum: 'Impressum',
                        privacy: 'Privacy Policy',
                        comaplaints: 'Complaints',
                        aszf: 'Terms and conditions',
                        travelPolicy: 'Travel Policy',
                        gdpr: 'GDPR',
                    },
                    ski: {
                        title: 'Ski offers',
                        description: 'Austria is a paradise for winter sports. Austrian ski resorts are world famous for their excellent, well-maintained ski slopes, infrastructure and welcoming atmosphere. Austria caters for beginners and experienced skiers and snowboarders, but Austrian ski resorts are also great destinations for families.',
                        description2: 'Austrian ski resorts offer more than just skiing. You can try snowmobiling, but there are also great local hiking opportunities, or relax in a wellness centre.\n' +
                            'Can\'t find the accommodation or ski resort you\'re looking for? Contact us, we know what\'s possible!',
                        regions: 'Austria',
                        accommodationDetail: 'Details of the accommodation'
                    },
                    blog: {
                        headline: 'Blog',
                        categories: 'Categories'
                    },
                    sport: {
                        description: "Please see our sport trips",
                        headline: "Sport trips",
                        description2: "Join us and experience first-hand what it's like to watch a match or a tournament up close and personal. Experience a new dimension in sports tourism and experience the art of cheering with us!",
                        headline2: "Join us for the cheering experience!",
                        actualSport: "Current sport trips",
                        inPictures: 'Sport trip by images',
                        numberOfGuests: 'Number of guests',
                        room: "Room",
                        optionalPrograms: "Optional programs",
                        mandatoryFees: "Mandatory fees",
                        previousSport: "Previous sport trips",
                        downloadProspect: 'Download the prospect',
                        inNumbers: 'Sport trips by numbers',
                        firstNumber: '',
                        firstNumberDescription: 'competitions we cheered on',
                        secondNumber: '',
                        secondNumberDescription: ' themed sports trips we organised',
                        thirdNumber: '',
                        thirdNumberDescription: 'cities we explored',
                        fourthNumber: '',
                        fourthNumberDescription: 'kms we covered on our sports trips',
                    }
                },
            },
            hu: {
                translation: {
                    home: {
                        title: "35 éve vezető szerep a hazai üzleti- és hivatásturizmusban",
                        subtitle: "Már 35 éve meghatározó szerepet töltünk be a hazai üzleti- és hivatásturizmusban. Célunk, hogy Önnek megbízható hátteret és átfogó turisztikai szolgáltatásokat nyújtsunk üzleti útjaihoz és szakmai rendezvényeihez, de számíthat ránk szabadidős útjai alkalmával is. Szolgáltatásaink rugalmasak, pontosak, gyorsak és ügyfélorientáltak.",
                        in_numbers: 'Jet Travel számokban',
                        refernces: 'Referenciák',
                        fifthNumber: "fő",
                        sixthNumber: "%",
                        seventhNumber: "",
                        eighthNumber: "+",
                        ninethNumber: "+",
                        tenthNumber: "+",
                        fifthNumberDescription: "szakképzett kolléga",
                        sixthNumberDescription: "magyar tulajdon",
                        seventhNumberDescription: "repülőjegy kiállítása évente",
                        eightNumberDescription: "rendezvény évente",
                        ninethNumberDescription: "incentive utazás évente",
                        tenthNumberDescription: "edzőtábor évente",
                        corporateTitle: "Üzleti utaztatás",
                        corporateDescription: "Az elmúlt 35 év szakmai tapasztalata az üzleti utaztatásban lehetővé tette számunkra, hogy közel 350 aktív szerződéses ügyféllel dolgozzunk együtt, akiknek 30 000 repülőjegyet állítunk ki a 12 hónap alatt. Megrendelőink között megtalálhatóak az autóipar, telekommunikáció, média és az állami szektor képviselői. Büszkék vagyunk arra, hogy egyre több sportszövetség és sportklub választ minket hivatalos útjaik megszervezéséhez.",
                        incentiveHeadline: "MICE & Bleisure",
                        incentiveDescription: "Ha rendezvényével, csapatépítésével vagy incentive útjával szeretne kilépni a komfortzónájából, és letérne a kitaposott ösvényről, nálunk jó helyen jár. Klubcsapata számára professzionális edzőtábort szeretne?\n" +
                            "Tapasztalt és lelkes csapatunk megszervezi céges rendezvényét, incentive útját vagy edzőtáborát A-tól Z-ig.\n" +
                            "Bízza ránk a szervezését, és garantáljuk a sikert!",
                        travelWithUsTitle: "Utazzon velünk",
                        travelWithUsDescription: "Tanácstalan, hogy hol töltse a vakációját? Van elképzelése, de nincs ideje vagy kedve megszervezni? Mi levesszük a válláról a szervezéssel járó terheket, legyen az adrenalin-növelő kaland- vagy gasztrotúra, üdülés, tengeri hajózás esetleg síelés a kedvelt vagy kevésbé ismert helyeken. Inkább csoportos úthoz csatlakozna? Nálunk akkor is jó helyen jár.\n" +
                            "Kollégáink igényeihez igazodva felkutatják Önnek álmai utazását. Honlapunkon egy helyen találja meg a legmegbízhatóbb magyar utazásszervezők kínálatait. Bízza ránk az utazásszervezést, és élvezze gondtalanul a tökéletes vakációt!",
                        seaTravelTitle: "Hajóutak",
                        seaTravelDescription: "A tengeri hajóút nem csupán utazás, hanem egy életérzés. Feledhetetlen a rengeteg élmény, amit egy hajóút nyújt. Minden nap más látnivalók és kultúrák, kényeztető szolgáltatások és a világ kulináris sokszínűsége várja az utazót a fedélzeten. Csatlakozzon Ön is a tengeri utazók csapatához, és fedezze fel velünk a világot a tenger hullámain! Egyéni és csoportos tengeri hajóutak széles választékát kínáljuk, hogy mindenki megtalálja a számára tökéletes élményt nyaralásához, pihenéséhez.\n" +
                            "Válassza a kalandot és a luxust egyaránt, és élvezze a tenger nyújtotta szabadságot és csodákat vakációján – elérhető áron.",
                        sportTitle: "Sportutak",
                        sportDescription: "Jet Travel a sport és sportturizmus iránt elkötelezett szakértők csapata. 17 sportág ki- és beutaztatásában, valamint szállásfoglalásában nyújtottunk segítséget. Tapasztalattal rendelkezünk világversenyek és edzőtáborok teljeskörű megszervezésében.\n" +
                            "Élje át velünk Ön is a sportturizmus egy másik dimenzióját, a szurkolás élményét!\n" +
                            "Exkluzív sport útjaink során különleges szemszögből mutatjuk be az amerikai kontinenst. Az izgalmas sportesemények mellett a kulturális látnivalók is fontos szerepet kapnak programjainkban. NHL, NBA, és NFL mérkőzéseken való részvétel szakértői kísérettel teszi teljessé az élményt.",
                        skiHeadline: "Síajánlatok",
                        skiDescription: "Ausztria a téli sportok paradicsoma. Az osztrák síterepek világhírűek a kiváló, jól karbantartott sípályáiról, az infrastruktúrájáról és a vendégszerető légköréről. Ausztria a kezdő és tapasztalt síelők illetve snowboardosok igényeinek is eleget tesz, de az osztrák síterepek kiváló úticélok családok számára is. Az osztrák síterepek többet kínálnak csupán a síelésnél. Kipróbálhatja a hóbiciklit, de nagyszerű helyi túrázási lehetőségeke is kínál, de pihenhet egy wellness központban is.\n" +
                            "Nem találja kínálatunkban az elképzelése szerinti szállást vagy síterepet? Vegye fel velünk a kapcsolatot, nem ismerünk lehetetlent!",
                    },
                    contact: {
                        contact_text: 'Ha kérdése merült vagy további információra lenne szüksége utazási szolgáltatásainkkal\n' +
                            'kapcsolatban, kérjük írjon nekünk a fenti mező használatával. Felhívjuk figyelmét, hogy\n' +
                            'kollégáink csak munkanapokon, munkaidőben (08:30 – 17:00 óra) olvassák üzenetét. Halaszthatatlan\n' +
                            'ügyben kérjük, ne ezt a felületet használja, hanem hívja ügyeleti telefonszámunkat. Munkaidőn\n' +
                            'kívül, sürgős esetekben csak az ügyeleti telefonszámon tett bejelentésekben tudunk segítségére\n' +
                            'lenni!',

                        contact: "Kapcsolat",
                        firstName: "Keresztnév",
                        lastName: "Vezetéknév",
                        phone: "Telefon",
                        email: "Email",
                        comment: "Megjegyzés",
                        contactText: "Ha bármilyen kérdése van, vagy további információra van szüksége, kérjük, forduljon hozzánk bizalommal."
                    },
                    general: {
                        send: "Küldés",
                        success: "Üzenetét sikeresen elküldtük.",
                        getQuote: "Kérjen Árajánlatot",
                        firstName: "Keresztnév",
                        lastName: "Vezetéknév",
                        phone: "Telefon",
                        email: "E-mail",
                        comment: "Megjegyzések",
                        back: "Vissza",
                        cancel: "Mégsem",
                        sum: "Összesen",
                        open: "Megnyitás",
                    },
                    errors: {
                        charError: 'Csak ékezet nélküli kisbetűs karaktereket tartalmazhat (a-z)',
                        minLengthError: 'Legalább {{minLength}} karakter hosszú kell hogy legyen',
                        maxLengthError: 'Legfeljebb {{maxLength}} karakter hosszú lehet',
                        emailError: 'Nem érvényes e-mail cím',
                        vatNumberError: 'Nem érvényes adószám, (xxxxxxxx-y-zz)',
                        numberError: 'Csak számokat tartalmazhat',
                        nonNegativeError: 'Nem lehet negatív szám',
                        requiredError: 'Nem maradhat üresen',
                        dateError: 'Helytelen dátum',
                    },
                    aboutUs: {
                        title: 'Rólunk',
                        description: 'Több mint utazási iroda',
                        description2: 'Meghatározó szereplői vagyunk a hazai üzleti- és hivatásturizmusnak. \n' +
                            'Elhivatottságunk eredményeként eddig 3 alkalommal ítélték nekünk a legrangosabb hazai szakmai díjat "A LEGJOBB CORPORATE UTAZTATÓ" címet, 2018. januárjától pedig az ATPI Corporate Travel kizárólagos magyarországi partnere lettünk.\n' +
                            'Célunk ügyfeleink részére megbízható hátteret, valamint mindenre kiterjedő turisztikai szolgáltatást nyújtani üzleti útjaikhoz, szakmai rendezvényeikhez rugalmas, pontos és gyors, ügyfélorientált kiszolgálás mellett. Munkánkat meghatározza a rugalmasság, a megbízhatóság és a minőség felső fokon. \n',
                        headline: '35 éve meghatározó szerep a hazai üzleti- és hivatásturizmusban',
                        firstCard: 'Miért válassza a Jet Travel-t?',
                        firstCardDescription: 'Célunk ügyfeleink részére megbízható hátteret, valamint mindenre kiterjedő turisztikai szolgáltatást nyújtani üzleti útjaikhoz, szakmai rendezvényeikhez rugalmas, pontos és gyors, ügyfélorientált kiszolgálás mellett. Munkánkat meghatározza a rugalmasság, a megbízhatóság és a minőség felső fokon. ',
                        secondCard: "Csapatunk",
                        secondCardDescription: "Csapatunk az üzleti- és hivatásturizmust jól ismerő, profi, lelkes és kreatív szakemberekből áll. Ügyfeleink számára magas minőségű, teljes körű üzleti utazási szolgáltatást biztosítunk az egyedi igényekhez és elképzelésekhez igazítva. Szabadidős utazása esetén is számíthat ránk, legyen az körutazás, tematikus sportutazás, tengeri hajóút, tengerparti üdülés vagy akár síelés.",
                        thirdCard: "EU-s képzések",
                        thirdCardDescription: "A Jet Travel elkötelezett munkavállalói továbbképzése iránt. Hiszünk abban, hogy minőségi szolgáltatást csak naprakész információk birtokában lehet nyújtani. Munkavállalóink így 2022. január 10-i kezdettel összesen négy, EU által finanszírozott képzésben vettek részt, amelyek segítették nyelvi, jogi- és adóügyi, kommunikációs készségeik fejlesztését, kiegészítve a jövő zöld, szociális és digitális igényeire kialakított készségfejlesztéssel, ami a modern turisztikai szolgáltatásnyújtás alapja.",
                        fourthCard: "Szakmai partnerek, díjak",
                        fourthCardDescription: "Ismerje meg ügyfeleink véleményét kiemelkedő szolgáltatásainkról és szakértelmünkről."
                    },
                    whyJet: {
                        title: "Miért válassza a Jet Travel-t?",
                        description: "Fedezze fel, miért érdemes a Jet Travel-t választani vállalati utazásaihoz.",
                        headline: "Néhány ok, amiért érdemes a Jet Travel-t választani",
                        firstCard: "Minden egy helyen, ami az üzleti úthoz kell",
                        firstCardDescription: "Teljeskörű idegenforgalmi szolgáltatást biztosítunk Önnek üzleti útjához a menetjegy vásárlástól, a szállásfoglaláson át konferencia regisztrációig.",
                        secondCard: "Lépést tartunk a fejlődéssel",
                        secondCardDescription: "Üzleti utazásszervezésünket a legmodernebb technológiák, adatbázisok és kereső motorok segítik, hogy a legjobb ár-érték arányú idegenforgalmi szolgáltatásokat biztosítsuk Önnek.",
                        thirdCard: "Az idő pénz",
                        thirdCardDescription: "Ezt Ön tudja a legjobban. Kollégáink sokéves szakmai gyakorlatuknak köszönhetően könnyen eligazodnak a foglalási felületek útvesztőiben, és mindig megtalálják a legjobb lehetőségeket. Ne vesztegesse értékes idejét üzleti útjának megszervezésével. Kérésére, a rendelkezésünkre adatok alapján közreműködünk cége travel policy-ének kialakításában is.",
                        fourthCard: "Dedikált ügyintéző",
                        fourthCardDescription: "A sikeres együttműködés egyik elengedhetetlen eleme a személyes kapcsolat. Mi dedikált ügyintézővel biztosítjuk ezt az Ön számára. ",
                        fifthCard: "24/7 asszisztencia - Gyors reakció",
                        fifthCardDescription: "Üzleti útjával kapcsolatos sürgős probléma megoldásában számíthat rénk hét minden napján munkaidőn túl is. Járattörlés? Járatmódosulás? Ne aggódjon, a lehető leggyorsabban értesítjük ezekről, a felmerülő problémákat pedig a lehetőségekhez mérten gyorsan kezeljük.",
                        sixthCard: "Nincs lehetetlen",
                        sixthCardDescription: "Az üzleti útja során egy hangszert, sporteszközt, különleges felszerelést vagy egy állatot szállítana? Több programot, embert és helyszínt kell összeszervezni? Mi megoldjuk ezeket a bonyolultnak tűnő helyzeteket is. Megkíméljük az utolsó pillanatban feleslegesen, kényszerből kifizetett pótdíjaktól."
                    },
                    corporate: {
                        title: "Üzleti utaztatás",
                        description: "Az elmúlt 35 év szakmai tapasztalata az üzleti utaztatásban lehetővé tette számunkra, hogy közel 350 aktív szerződéses ügyféllel dolgozzunk együtt, akiknek 30 000 repülőjegyet állítunk ki a 12 hónap alatt. Megrendelőink között megtalálhatóak az autóipar, telekommunikáció, média és az állami szektor képviselői. Büszkék vagyunk arra, hogy egyre több sportszövetség és sportklub választ minket hivatalos útjaik megszervezéséhez.",
                        heading: "Szolgáltatásaink",
                        heading2: "Üzletpolitikánk",
                        inNumbers: "Üzleti utaztatás számokban",
                        description2: "Rugalmasság: Minden igényhez igazodó, testreszabott megoldások.\n" +
                            "Megbízhatóság: Biztonság és kiszámíthatóság minden lépésben.\n" +
                            "Minőség felső fokon: Kiemelkedő színvonalú szolgáltatások.",
                        firstCard: "Menetjegy - Földön, vízen, levegőben",
                        firstCardDescription: "Elvárásaihoz igazodva üzleti útjához megtaláljuk Önnek a leggyorsabb és legbiztosabb útvonalat úti céljához. IATA irodaként bármelyik légitársaság járatára biztosítunk foglalást, de megválthatja menetjegyét nálunk autóbuszra, vonatra, hajóra is.",
                        secondCard: "Szállásfoglalás – Sikerének egyik záloga, a pihentető alvás",
                        secondCardDescription: "Nemzetközi foglalási rendszereinknek és kiterjedt partnerkapcsolatainknak köszönhetően - bármely kategóriában, versenyképes áron foglaljuk le Önnek az ideális szállását üzleti útjához.",
                        thirdCard: "Reptéri transzfer? Autóbérlés? – Bárhol a világon",
                        thirdCardDescription: "Versenyképes árakban és megbízható partnerekkel (Hertz, Avis, Europcar stb.) számíthat ránk üzleti útja során. Autóbérléséhez full credit voucher-t biztosítunk, amellyel elkerülhetővé válnak a helyszínen felszámítandó extra költségek.",
                        fourthCard: "Vízumügyintézés – a világ összes országába",
                        fourthCardDescription: "Ne vesszen el a vízumkérelmek és beutazási engedélyek bonyolult útvesztőjében! Vízumügyintézését megoldjuk a világ összes országába (kivéve, ahol az egyéni eljárás a kötelező).",
                        fifthCard: "Biztosítás – Tudja biztonságban utazását!  ",
                        fifthCardDescription: "A világ minden részére kínálunk komplex biztosítási szolgáltatásokat (Alfa, EUB és Colonnade Biztosítók). \nA nyugodt utazás egyik kulcsa: Baleset-, Betegség-, Poggyászbiztosítás ",
                        sixthCard: "Forgalmi kimutatások, statisztikák",
                        sixthCardDescription: "Ügyfeleink számára igény szerint tételes kimutatást biztosítunk havi vagy negyedéves bontásban az előző időszak forgalmáról. Egyedileg kialakított információs rendszerünk segítségével folyamatosan analizáljuk ügyfeleink utazási struktúráját, így mindig naprakész tanácsokkal tudjuk ellátni.",
                        firstNumber: "év",
                        firstNumberDescription: "szakmai tapasztalat üzleti és hivatásturizmus terén",
                        secondNumber: "ügyfél",
                        secondNumberDescription: "kiszolgálása naponta",
                        thirdNumber: "top",
                        thirdNumberDescription: "legmeghatározóbb corporate utaztatók egyike Magyarországon",
                        fourthNumber: "alkalommal",
                        fourthNumberDescription: "nyertük el a „Legjobb Corporate” utaztató címet",
                    },
                    csr: {
                        title: "CSR",
                        description: "A vállalati társadalmi felelősségvállalás (CSR) a vállalati kultúránk szerves részét képezi. ",
                        headline: "CSR kezdeményezéseink",
                        firstCardTitle: 'Munkhelyi feltételek biztosítása',
                        firstCardDescription: 'Hisszük, hogy munkatársaink akkor tudnak azonosulni és hitelesen képviselni cégünk értékrendjét az ügyfeleink, a társadalom felé, ha saját maguk vallják, hogy jó jetesnek lenni. Ehhez megfelelő támogató hátteret kell teremtenünk. Ez nemcsak a rendszeres szakmai és nyelvi továbbképzés lehetőségét jelenti, hanem a család- és állatbarát munkahely biztosítását, valamint az otthoni munkavégzés lehetőségét is. Támogatjuk és elismerjük a pozitív kezdeményezéseket, jutalmazzuk kollégáink kiemelkedő teljesítményét.',
                        secondCardTitle: 'Kis tettek, nagy álmok',
                        secondCardDescription: 'Gyakran kis tettek válthatják valóra a nagy álmokat. Ezért is támogatjuk hosszú évek óta a „Csodalámpa Alapítványt”, amely számos hosszan tartó betegségben szenvedő gyermek kívánságának teljesülését segítette már. Emellett ad hoc jelleggel veszünk részt óvodák, iskolák, speciális szociális intézmények egyedi gyűjtéseinek támogatásában.',
                        thirdCardTitle: 'Sport – az ő sikere, a mi sikerünk is',
                        thirdCardDescription: 'A sport elkötelezett hívei vagyunk. Rendszeresen támogatunk sportegyesületeket, hogy minél több sportoló érhesse el élete legjobb eredményét.',
                        fourthCardTitle: 'Turizmus és fenntarthatóság',
                        fourthCardDescription: 'Feltöltés alatt...',
                    },
                    colleagues: {
                        title: "Csapatunk",
                        description: "Ismerje meg csapatunkat, akik az utazásához magas minőségű, teljes körű utazási szolgáltatásokat biztosítják."
                    },
                    incentive: {
                        title: "MICE & Bleisure",
                        description: "Mi a sikeres hivatásturizmus titka? Kreativitás, kapcsolatrendszer és egy jó ötlet.\n" +
                            "Ha szeretne rendezvényével, csapatépítésével vagy incentive útjával kilépni a komfortzónájából, és letérne a kitaposott ösvényről, akkor nálunk jó helyen jár.\n" +
                            "Mi tudjuk mi a különbség a hivatásturizmus, az üzleti út és a szabadidős szolgáltatások között.\n" +
                            "Tapasztalt és lelkes csapatunk megszervezi céges rendezvényét vagy incentive útját A-tól Z-ig. Kitaláljuk a koncepciót, megkeressük a legjobb helyszínt, mindezt megfűszerezzük különleges programokkal. Igény szerint megtervezzük az arculatot és a kreatív tartalmakat, biztosítjuk az online regisztrációt, kezeljük a szálláskontingenst, megkötjük a szerződéseket, kézben tartjuk a pénzügyi folyamatokat és biztosítjuk a tökéletes kivitelezést 10-től 1000 főig.",
                        headline: "Rendezvény, incentive és bleisure - Egyedi igényekre hangolva",
                        inNumbers: "Eredményeink számokban",
                        numberOfGuests: "Vendégek száma",
                        startDate: "Kezdési Dátum",
                        endDate: "Befejezési Dátum",
                        inquiryType: "Érdeklődés Típusa",
                        catering: "Ellátás",
                        accommodation: "Szállás",
                        "3starHotel": "3-csillagos hotel",
                        "4starHotel": "4-csillagos hotel",
                        "5starHotel": "5-csillagos hotel",
                        firstCard: "Meeting",
                        firstCardDescription: "Az üzleti találkozó sikere a részletekben is rejlik\nGondolta volna, hogy egy cég sikerének titka akár az üzleti találkozójának minőségén is múlik? Tökéletes helyszínválasztás, kreatív program ötletek, minőségi szolgáltatásnyújtás! Mindebben számíthat ránk!\n" +
                            "Bízza profikra, bízza ránk a szervezést, legyen az néhány fős üzleti megbeszélés vagy üzleti reggeli, esetleg ciklus meeting vagy workshop.",
                        secondCard: "Incentive utazások",
                        secondCardDescription: "Hogy az élmény valóban motiváló legyen\nAz egyedi igényre szabott incentive út szervezése kiváló módja annak, hogy jutalmazza és motiválja munkatársait az átélt kalandok révén. Lépjen ki a komfortzónájából! Az egyedi igényre szabott incentive út szervezése kiváló módja annak, hogy jutalmazza és motiválja munkatársait az átélt kalandok révén. Tudta, hogy egy incentive út sikere leginkább a megfelelően kiválasztott úti célon, a kreatív programokon és a tökéletes szervezésen múlik? Egyedi igényekre szabott utazások, különleges elképzelések? Nekünk mindig van pluszban egy jó ötletünk!\n" +
                            "Az Ön ösztönző programjához és cégéhez melyik típusú incentive út illik:\n" +
                            "• Luxus incentive út egzotikus helyszínekre\n" +
                            "• Kalandos incentive programok adrenalinra vágyóknak\n" +
                            "• Kulturális incentive út a világ legérdekesebb városaiba\n" +
                            "• Wellness és relaxációs incentive út a teljes kikapcsolódásért",
                        thirdCard: "Konferenciák",
                        thirdCardDescription: "Ne tévedjen el a konferenciaszervezés útvesztőjében!\nA konferenciaszervezés nagyon összetett és sok előkészületet, odafigyelést igénylő munkafolyamat. Megfelelő tervezéssel és végrehajtással egy emlékezetes és sikeres rendezvényt hozhatunk létre. Ne vesszen el a konferenciaszervezés és lebonyolítás útvesztőjében! Számíthat ránk a célkitűzések meghatározásában, költségvetés készítésében, helyszín kiválasztásában, a programok összeállításában, az előadók meghívásában, a technikai eszközök és technikai háttér biztosításában, a marketing és promóciós feladatokban, szállákontingens kezelésében. Biztosítjuk Önnek mindezt, hogy a konferencia gördülékenyen és eredményesen végződjön. Az Ön sikere a mi sikerünk is!",
                        fourthCard: "Kiállítások & Események",
                        fourthCardDescription: "Ne vesztegesse vele az idejét! Bízza ránk a szervezést!",
                        fifthCard: "Céges rendezvények és csapatépítés",
                        fifthCardDescription: "Kovácsolja össze csapatát!\nNem elég egy céges rendezvényt megszervezni, annak tökéletesen tükröznie kell a vállalata arculatát és márkáját. Nekünk mindig van egy lenyűgöző ötletünk. Nem riadunk vissza a kihívásoktól, legyen szó izgalmas vagy szórakoztató csapatépítés Budapesten, vidéken, a városban vagy a vadonban. A csapatépítés sikere maradandó élményt nyújt, amelyet kollégái az Ön cégéhez kötnek. Bízza ránk a rendezvényszervezést és biztosítjuk, hogy minden esemény emlékezetes és sikeres legyen.\n" +
                            "Ha pedig szeretne részt venni egy kiállításon vagy konferencián, és szüksége van regisztrációra, menetjegyre és szállásra, akkor ne vesztegesse az idejét! Bízza ránk a szervezést és a foglalást! Levesszük a válláról a terhet, hogy Ön a fontosabb dolgokra koncentrálhasson.\n",
                        sixthCard: " Belisure",
                        sixthCardDescription: "Több mint üzleti út\nÜzleti és szabadidős utazás egyben? Miért is ne. Ha szeretné növelni kollégáija elkötelezettségét cége iránt, a megoldás: bleisure.\n" +
                            "Egyre több munkahely ismeri fel az üzleti- és a szabadidős utazás kombinálásának pozitív hatását. Mi segíthetünk Önnek abban, hogy az üzleti út ne csak munka legyen, hanem motivációt és élményt jelentsen kollégája számára.",
                        seventhCard: "Sportturizmus és Sport rendezvények szervezése",
                        seventhCardDescription: "Egyéni és csapatszinten\nCégünk a sport és sportturizmus iránt elkötelezett szakértők csapata. Az években egyéni sportolók, csapatok és sportszövetségek ki- és beutaztatásában, valamint szállásfoglalásában nyújtunk segítséget. Kiemelkedő tapasztalattal rendelkezünk világversenyek és edzőtáborok teljes körű megszervezésében. Jelenleg 17 sportágban vagyunk aktívak, és büszkén mondhatjuk, hogy a hazai sportturizmus meghatározó és vezető szereplői vagyunk. ",
                        firstNumber: "+",
                        firstNumberDescription: "Incentive utazás évente",
                    },
                    notFound: {
                        title: "Az oldal nem található",
                        headline: "Az oldal nem található",
                        text: "Úgy tűnik, hogy rossz helyen jársz. Ne aggódj, a legjobb utazók is néha eltévednek!"
                    },
                    sustainability: {
                        title: "Fenntarthatóság",
                        description: "A Jet Travel elkötelezett a környezeti felelősségvállalás szemléletének elterjesztésében mind az idegenforgalmi szektorban, mind a vállalaton belül. A fenntarthatóság kultúrájának kialakításával arra ösztönözzük munkatársainkat, partnereinket és ügyfeleinket, hogy környezettudatos döntéseket hozzanak, amelyek hozzájárulnak egy egészségesebb bolygóhoz. Hisszük, hogy a kisebb, következetes lépések is jelentős pozitív változásokhoz vezethetnek.",
                        // headline: "Tudjon meg többet fenntartható gyakorlatainkról",
                        firstCard: 'Innováció a fenntartható jövőért ',
                        firstCardDescription: 'Célunk a fenntartható turizmus, a környezettudatos utazás népszerűsítése. Küldetésünknek tekintjük, hogy ügyfeleinket támogassuk a fenntarthatóbb utazási döntések meghozatalában. Mindezek támogatásához folyamatban van CO2 kalkulátorunk kifejlesztése, amely kifejezetten a légi közlekedésre összpontosít, és lehetővé teszi partnereink számára, hogy pontosan felmérjék utazásaik környezeti hatását, és lehetőséget biztosít majd a szén-dioxid-kibocsátás kompenzálására. ',
                        secondCard: 'Travelife minősítés ',
                        secondCardDescription: 'A Jet Travel elkötelezett a Travelife minősítés megszerzése iránt, amely egy rangos elismerés azon utazási irodák számára, amelyek megfelelnek a szigorú fenntarthatósági előírásoknak. Ez a tanúsítási folyamat átfogó értékelést igényel környezeti-, társadalmi- és irányítási gyakorlatainkról, biztosítva, hogy a legmagasabb szintű fenntarthatósági normáknak megfelelően működjünk. A tanúsítás megszerzésével elkötelezettségünket bizonyítjuk a folyamatos fejlődés és a fenntartható turizmus iránti vezető szerep iránt.',
                        thirdCard: 'Megfelelés az etikai normáknak',
                        thirdCardDescription: 'A Jet Travel számára kiemelten fontos a társadalmi felelősségvállalás. Mind hazai, mind külföldi partnereink kiválasztásánál elvárt, hogy a legmagasabb etikai normáknak felelnek meg, hogy ne merüljön fel esetükben az illegális- és/vagy gyermekmunka. Az elfogadás mellett elvárt, hogy munkatársunkat tisztelet és megbecsülés övezze. Ezzel nemcsak a nemzetközi munkajogi előírásoknak teszünk eleget, hanem a tisztelet és méltóság kultúráját is erősítjük minden tevékenységünkben.',
                        fourthCard: 'Ökológiai lábnyom csökkentése',
                        fourthCardDescription: 'A Jet Travel aktívan dolgozik az irodai energiafogyasztás mérséklésével. Intézkedéseink közé tartozik az irodai papírmentességre való átállás, a távmunkát elősegítő intézkedések, valamint a fenntartható közlekedési lehetőségek például a közös autóhasználat vagy kerékpár, roller, tömegközlekedés népszerűsítése. Ezek az intézkedések összhangban vannak fenntarthatósági elkötelezettségünkkel, segítve bennünket szén-dioxid-lábnyomunk csökkentésében és a tisztább környezet előmozdításában.',
                    },
                    travelWithUs: {
                        title: "Utazzon velünk",
                        description: "Fedezze fel a világot a Jet Travel-lel",
                        headline: "Fedezze fel utazási lehetőségeinket",
                        sea: "Hajóutak",
                        organized: "Szervezett utazások",
                        text: "Csatlakozzon hozzánk egy életre szóló utazásra, számos utazási csomag közül választhat, amelyek minden igényt kielégítenek. Legyen szó luxushajóutakról, kalandtúrákról vagy kulturális körutazásokról, nálunk mindenki megtalálja a számára megfelelőt."
                    },
                    menu: {
                        home: "Kezdőlap",
                        contact: "Kapcsolat",
                        sport: "Sportutak",
                        aboutUs: "Rólunk",
                        corporate: "Üzleti utaztatás",
                        incentive: "MICE & Bleisure",
                        travelWithUs: "Utazzon velünk",
                        sustainability: "Fenntarthatóság",
                        csr: "CSR",
                        blog: "Blog",
                        ski: "Síutak",
                        colleagues: "Munkatársak",
                        cruises: "Tengeri hajózás"
                    },
                    footer: {
                        aboutUs: "Rólunk",
                        aboutUsDescription: "Több mint utazási iroda.",
                        links: "Gyorslinkek",
                        contact: "Kapcsolat",
                        allRightsReserved: "Minden jog fenntartva.",
                        impressum: 'Impresszum',
                        privacy: 'Adatkezelési szabályzat',
                        comaplaints: 'Panaszkezelés',
                        aszf: 'ÁSZF',
                        travelPolicy: 'Utazási feltételek',
                        gdpr: 'GDPR',
                    },
                    ski: {
                        title: 'Síajánlatok',
                        regions: 'Ausztria',
                        accommodationDetail: 'Szállás adatai',
                        description: 'Ausztria a téli sportok paradicsoma. Az osztrák síterepek világhírűek a kiváló, jól karbantartott sípályáiról, az infrastruktúrájáról és a vendégszerető légköréről. Ausztria a kezdő és tapasztalt síelők illetve snowboardosok igényeinek is eleget tesz, de az osztrák síterepek kiváló úticélok családok számára is. ',
                        description2: 'Az osztrák síterepek többet kínálnak csupán a síelésnél. Kipróbálhatja a hóbiciklit, de nagyszerű helyi túrázási lehetőségeke is kínál, de pihenhet egy wellness központban is.\n' +
                            'Nem találja kínálatunkban az elképzelése szerinti szállást vagy síterepet? Vegye fel velünk a kapcsolatot, nem ismerünk lehetetlent!\n',
                    },
                    blog: {
                        headline: 'Blog',
                        categories: 'Kategóriák'
                    },
                    sport: {
                        description: "Exkluzív sport útjaink során különleges szemszögből mutatjuk be az amerikai kontinenst. Az izgalmas sportesemények mellett a kulturális látnivalók is fontos szerepet kapnak programjainkban. NHL, NBA, és NFL mérkőzéseken való részvétel szakértői kísérettel teszi teljessé az élményt.",
                        description2: "Csatlakozzon hozzánk, és tapasztalja meg első kézből, milyen fantasztikus érzés egy mérkőzést vagy versenyt közvetlen közelről végigizgulni. Ismerje meg a sportturizmus új dimenzióit, és élje át velünk a szurkolás művészetét!",
                        headline: "Sportutak az Amerikai kontinensre",
                        headline2: "Élje át Ön is a szurkolás élményét velünk!",
                        actualSport: "Aktuális sportutak",
                        inPictures: 'Utazás képekben',
                        numberOfGuests: 'Vendégek száma',
                        room: "Szoba",
                        optionalPrograms: "Fakultatív programok",
                        mandatoryFees: "Kötelező díjak",
                        previousSport: "Korábbi sportutak",
                        downloadProspect: 'Töltse le a prospektust',
                        inNumbers: 'Sportutak számokban',
                        firstNumber: '',
                        firstNumberDescription: 'mérközésen szurkoltunk',
                        secondNumber: '',
                        secondNumberDescription: 'tematikus sportutat szerveztünk',
                        thirdNumber: '',
                        thirdNumberDescription: 'várost fedeztünk fel',
                        fourthNumber: '',
                        fourthNumberDescription: 'km-t tettünk meg sportútjainkon',
                    }

                },
            }
        }
    });

export default i18n;
