import Footer from "../components/Footer";
import {Box, Button, Container, Typography} from "@mui/material";
import HeroImage from "../components/HeroImage";
import useScrollToTop from "../hooks/useScrollToTop";
import ImageStack from "../components/ImageStack";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import whyJet from '../assets/aboutUs/Miert_JET_Travel.jpg'
import firstCard from '../assets/aboutUs/corporateTravel.jpg'
import secondCard from '../assets/aboutUs/improvement.jpg'
import thirdCard from '../assets/aboutUs/timeIsMoney.jpg'
import fourthCard from '../assets/aboutUs/dedicatedPerson.jpg'
import fifthCard from '../assets/aboutUs/247assistance.jpg'
import sixthCard from '../assets/aboutUs/nothingIsImpossible.jpg'
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {useHistory} from "react-router-dom";

const WhyJet = () => {
    useScrollToTop()
    const {t} = useTranslation()
    const history = useHistory()
    document.title = `Jet Travel | ${t('whyJet.title')}`

    const cards = [
        {
            image: firstCard,
            title: t('whyJet.firstCard'),
            description: t('whyJet.firstCardDescription'),
        },
        {
            image: secondCard,
            title: t('whyJet.secondCard'),
            description: t('whyJet.secondCardDescription'),
        },
        {
            image: thirdCard,
            title: t('whyJet.thirdCard'),
            description: t('whyJet.thirdCardDescription'),
        },
        {
            image: fourthCard,
            title: t('whyJet.fourthCard'),
            description: t('whyJet.fourthCardDescription'),
        },
        {
            image: fifthCard,
            title: t('whyJet.fifthCard'),
            description: t('whyJet.fifthCardDescription'),
        },
        {
            image: sixthCard,
            title: t('whyJet.sixthCard'),
            description: t('whyJet.sixthCardDescription'),
        },
    ]
    return <DrawerAppbar fade>
        <HeroImage description={t('aboutUs.firstCardDescription')} title={t('whyJet.title')} underlineColor='#ff0000'  image={whyJet}/>
        <div style={{marginTop: '64px'}}>

            <Box sx={{margin: 3}}>
                <Button sx={{marginBottom: 2}} onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>

            <Container sx={{marginTop: 10, marginBottom: 10}}>
                <Typography variant='h2' gutterBottom textAlign='center'>{t('whyJet.headline')}</Typography>
            </Container>

            <ImageStack images={cards}/>

            <Box sx={{marginTop: 10}}/>
        </div>
        <Footer/>
    </DrawerAppbar>
}

export default WhyJet