import Footer from "../components/Footer";
import {Box, Button, Container, FormControl, Grid, IconButton, MenuItem, Stack, Typography} from "@mui/material";
import HeroImage from "../components/HeroImage";
import useScrollToTop from "../hooks/useScrollToTop";
import {useSnackbar} from "notistack";
import {useRef, useState} from "react";
import {
    validateEmail,
    validateNonNegativeNumber,
    validateNumber,
    validateRequired
} from "../components/formValidation/validators";
import ErrorHandlingForm from "../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../components/formValidation/CustomTextField";
import CustomSelect from "../components/formValidation/CustomSelect";
import CustomDatePicker from "../components/formValidation/CustomDatePicker";
import CountUpGrid from "../components/CountUpGrid";
import useCallDataApi from "../hooks/data";
import Loading from "../components/Loading";
import {formatHuman} from "../utils/utils";
import ImageStack from "../components/ImageStack";
import DrawerAppbar from "../components/DrawerAppbar";
import {useTranslation} from "react-i18next";
import mainImage from '../assets/incentive/main.jpeg'
import firstImage from '../assets/incentive/01_Meeting.jpg'
import secondImage from '../assets/incentive/02_incentive.jpg'
import thirdImage from '../assets/incentive/03_conference.jpg'
import fourthImage from '../assets/incentive/04_Event.jpg'
import fifthImage from '../assets/incentive/05_Bleisure.jpg'
import sixthImage from '../assets/incentive/06_Sport_tourism.jpg'
import seventhImage from '../assets/incentive/07.jpg'
import CustomDialog from "../components/CustomDialog";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {useHistory} from "react-router-dom";


const Incentive = () => {
    useScrollToTop()
    const {t} = useTranslation()
    const history = useHistory()
    document.title = `Jet Travel | ${t('incentive.title')}`

    const cards = [
        {
            image: firstImage,
            title: t('incentive.firstCard'),
            description: t('incentive.firstCardDescription'),
        },
        {
            image: secondImage,
            title: t('incentive.secondCard'),
            description: t('incentive.secondCardDescription'),
        },
        {
            image: thirdImage,
            title: t('incentive.thirdCard'),
            description: t('incentive.thirdCardDescription'),
        },
        {
            image: fourthImage,
            title: t('incentive.fifthCard'),
            description: t('incentive.fifthCardDescription'),
        },
        {
            image: fifthImage,
            title: t('incentive.sixthCard'),
            description: t('incentive.sixthCardDescription'),
        },
        {
            image: sixthImage,
            title: t('incentive.seventhCard'),
            description: t('incentive.seventhCardDescription'),
        },
        {
            image: seventhImage,
            title: t('incentive.fourthCard'),
            description: t('incentive.fourthCardDescription'),
        },
    ]
    const {postData: postInquiry} = useCallDataApi('incentive-inquiry')
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false)
    const initialState = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        comment: '',
        inquiry_type: '',
        number_of_guests: '',
        catering: '',
        accommodation: '',
        start_date: new Date(),
        end_date: new Date(),
    }
    const [formData, setFormData] = useState(initialState);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [errors, setErrors] = useState({
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        comment: false,
        inquiry_type: false,
        number_of_guests: false,
        catering: false,
        accommodation: false,
        start_date: false,
        end_date: false,
    });

    const validators = {
        first_name: [validateRequired],
        last_name: [validateRequired],
        phone: [validateRequired],
        email: [validateRequired, validateEmail],
        comment: [],
        inquiry_type: [validateRequired],
        number_of_guests: [validateRequired, validateNumber, validateNonNegativeNumber],
        catering: [validateRequired],
        accommodation: [validateRequired],
        start_date: [validateRequired],
        end_date: [validateRequired],
    };

    const numbers = [
        {number: 35, unit: t('corporate.firstNumber'), description: t('corporate.firstNumberDescription')},
        {number: 150, unit: t('home.eighthNumber'), description: t('home.eightNumberDescription')},
        {number: 30, unit: t('home.tenthNumber'), description: t('home.tenthNumberDescription')},
        {number: 40, unit: t('incentive.firstNumber'), description: t('incentive.firstNumberDescription')},
    ]

    const contactRef = useRef(null);

    return <DrawerAppbar fade>
        <HeroImage darken={0.4} title={t('incentive.title')} underlineColor='#ff0000' image={mainImage}/>
        <div style={{marginTop: '64px', marginBottom: '64px'}}>

            <Box sx={{margin: 3}}>
                <Button sx={{marginBottom: 2}} onClick={() => history.goBack()} variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}>{t('general.back')}</Button>
            </Box>

            <Container sx={{marginTop: 10, marginBottom: 10}}>
                <Typography variant='h2' gutterBottom textAlign='center'>{t('incentive.headline')}</Typography>
                <Typography variant='body1' gutterBottom textAlign='center'>{t('incentive.description')}</Typography>
            </Container>

            <ImageStack images={cards}/>

            <Container sx={{marginTop: 10, marginBottom: 10}}>
                <Typography variant="h2" gutterBottom textAlign='center'>
                    {t('incentive.inNumbers')}
                </Typography>
                <CountUpGrid numbers={numbers} />
            </Container>

            <Container sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Button variant='outlined' onClick={() => setDialogOpen(true)}>{t('general.getQuote')}</Button>
            </Container>

        </div>
        <Footer />

        <CustomDialog
            open={dialogOpen}
            title={t('general.getQuote')}
            handleClose={() => setDialogOpen(false)}>
            <Typography marginTop={4} variant="h2" component="h1" gutterBottom textAlign='center'>
                {t('general.getQuote')}
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} sx={{ mx: 'auto' }}>
                    <ErrorHandlingForm
                        data={formData}
                        validators={validators}
                        setErrors={setErrors}
                        errors={errors}
                        setData={setFormData}
                        reference={contactRef}
                    >
                        <Stack spacing={2}>
                            <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{width: '100%'}}>
                                <Box sx={{width: '100%'}}>
                                    <Typography variant="body1" gutterBottom>
                                        {t('general.lastName')}
                                    </Typography>
                                    <CustomTextField
                                        name='last_name'
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                    />
                                </Box>
                                <Box sx={{width: '100%'}}>
                                    <Typography variant="body1" gutterBottom>
                                        {t('general.firstName')}
                                    </Typography>
                                    <CustomTextField
                                        name='first_name'
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        sx={{width: '100%'}}
                                    />
                                </Box>
                            </Stack>
                            <Box>
                                <Typography variant="body1" gutterBottom>
                                    {t('general.phone')}
                                </Typography>
                                <CustomTextField
                                    name='phone'
                                    data={formData}
                                    setData={setFormData}
                                    errors={errors}
                                    setErrors={setErrors}
                                    validators={validators}
                                    sx={{width: '100%'}}
                                />
                            </Box>
                            <Box>
                                <Typography variant="body1" gutterBottom>
                                    {t('general.email')}
                                </Typography>
                                <CustomTextField
                                    name='email'
                                    data={formData}
                                    setData={setFormData}
                                    errors={errors}
                                    setErrors={setErrors}
                                    validators={validators}
                                    sx={{width: '100%'}}
                                />
                            </Box>
                            <Box>
                                <Typography variant="body1" gutterBottom>
                                    {t('incentive.numberOfGuests')}
                                </Typography>
                                <CustomTextField
                                    name='number_of_guests'
                                    data={formData}
                                    setData={setFormData}
                                    errors={errors}
                                    setErrors={setErrors}
                                    validators={validators}
                                    sx={{width: '100%'}}
                                />
                            </Box>
                            <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{width: '100%'}}>
                                <Box sx={{width: '100%'}}>
                                    <Typography variant="body1" gutterBottom>
                                        {t('incentive.startDate')}
                                    </Typography>
                                    <CustomDatePicker
                                        inputFormat="yyyy-MM-dd"
                                        mask="____-__-__"
                                        name="start_date"
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        disablePast
                                        sx={{width: '100%'}}
                                    />
                                </Box>
                                <Box sx={{width: '100%'}}>
                                    <Typography variant="body1" gutterBottom>
                                        {t('incentive.endDate')}
                                    </Typography>
                                    <CustomDatePicker
                                        inputFormat="yyyy-MM-dd"
                                        mask="____-__-__"
                                        name="end_date"
                                        data={formData}
                                        setData={setFormData}
                                        errors={errors}
                                        setErrors={setErrors}
                                        validators={validators}
                                        disablePast
                                        sx={{width: '100%'}}
                                    />
                                </Box>
                            </Stack>
                            <Box>
                                <Typography variant="body1" gutterBottom>
                                    {t('incentive.inquiryType')}
                                </Typography>
                                <CustomSelect
                                    name="inquiry_type"
                                    data={formData}
                                    setData={setFormData}
                                    errors={errors}
                                    setErrors={setErrors}
                                    validators={validators}
                                    sx={{width: '100%'}}
                                >
                                    <MenuItem value='incentive'>Incentive</MenuItem>
                                    <MenuItem value='event'>Event</MenuItem>
                                </CustomSelect>
                            </Box>
                            <Box>
                                <Typography variant="body1" gutterBottom>
                                    {t('incentive.catering')}
                                </Typography>
                                <CustomSelect
                                    name="catering"
                                    data={formData}
                                    setData={setFormData}
                                    errors={errors}
                                    setErrors={setErrors}
                                    validators={validators}
                                    sx={{width: '100%'}}
                                >
                                    <MenuItem value='no-meals'>Semmi</MenuItem>
                                    <MenuItem value='breakfast'>Csak reggeli</MenuItem>
                                    <MenuItem value='half-board'>Félpanzió</MenuItem>
                                    <MenuItem value='full-board'>Teljes ellátás</MenuItem>
                                </CustomSelect>
                            </Box>
                            <Box>
                                <Typography variant="body1" gutterBottom>
                                    {t('incentive.accommodation')}
                                </Typography>
                                <CustomSelect
                                    name="accommodation"
                                    data={formData}
                                    setData={setFormData}
                                    errors={errors}
                                    setErrors={setErrors}
                                    validators={validators}
                                    sx={{width: '100%'}}
                                >
                                    <MenuItem value='3-star-hotel'>{t('incentive.3starHotel')}</MenuItem>
                                    <MenuItem value='4-star-hotel'>{t('incentive.4starHotel')}</MenuItem>
                                    <MenuItem value='5-star-hotel'>{t('incentive.5starHotel')}</MenuItem>
                                </CustomSelect>
                            </Box>
                            <Box>
                                <Typography variant="body1" gutterBottom>
                                    {t('general.comment')}
                                </Typography>
                                <CustomTextField
                                    name='comment'
                                    data={formData}
                                    setData={setFormData}
                                    errors={errors}
                                    setErrors={setErrors}
                                    validators={validators}
                                    sx={{width: '100%'}}
                                    multiline
                                    rows={3}
                                />
                            </Box>
                            <Button variant='contained' onClick={() => {
                                const data = contactRef.current.getData();
                                if (data) {
                                    setLoading(true)
                                    postInquiry('', {...data, start_date: formatHuman(data?.start_date), end_date: formatHuman(data?.end_date)})
                                        .then(() => {
                                            enqueueSnackbar(t('general.success'), {variant: 'success'})
                                            setFormData(initialState)
                                        })
                                        .finally(() => setLoading(false))
                                }

                            }}>{t('general.send')}</Button>
                        </Stack>
                    </ErrorHandlingForm>

                </Grid>
            </Grid>
        </CustomDialog>

        <Loading isLoading={loading}/>
    </DrawerAppbar>
}

export default Incentive