import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const EmployeeAvatar = ({ name, imageUrl, gifUrl }) => {
    const [hovered, setHovered] = useState(false);

    return <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Avatar
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                src={hovered ? gifUrl : imageUrl}
                alt={name}
                sx={{ width: 100, height: 100 }}
            />
        <Typography textAlign='center' variant="subtitle1" sx={{ marginTop: 1 }}>
            {name}
        </Typography>
    </Box>
};

export default EmployeeAvatar;
